import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { HandlerProps } from 'react-reflex';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import {
  LoadingOverlay,
  NavTab,
  NavTabTheme,
  StatusIndicatorStatus,
  StatusMarkState,
  Switch,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { FindingsConfirmationProvider } from 'components/FindingsConfirmationModal';
import { withLayout } from 'components/Layout';
import { PageLeavePrompt } from 'components/PageLeavePrompt';
import { ReportPreviewModal } from 'components/ReportPreviewModal';
import { ResizeContainer } from 'components/ResizeContainer';
import { StudyDetailsHeader } from 'components/StudyDetailsHeader';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { StudyStatus } from 'enums/StudyStatus.enum';
import { useStore } from 'hooks/store';
import { useDelayedLoading } from 'hooks/useDelayedLoading';
import { useStudyDetailsLoading } from 'hooks/useStudyDetailsLoading';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';
import { shortcutsBaseKeys } from 'services/keyboardShortcuts/shortcutsBaseKeys';
import { combineSaveStatus } from 'utils/combineSaveStatus';
import { MD_SCREEN_MAX_WIDTH } from 'utils/constants';

import styles from './StudyDetailsPage.module.scss';

export const studyNavTabs = [
  {
    label: 'Study Details',
    route: Routes.StudyDetails
  },
  {
    label: 'Findings',
    route: Routes.StudyFindings
  },
  {
    label: 'Conclusion',
    route: Routes.StudyConclusion
  }
];

export const StudyDetailsPage: React.FC = withLayout((hasConnectionIssues) =>
  hasConnectionIssues ? styles['snack-wrapper'] : styles.wrapper
)(
  observer(() => {
    const { id } = useParams<{ id: string }>();
    const { t } = useCustomTranslation();
    const { windowWidth } = useWindowWidth();
    const [isModalOpen, setOpenModal] = useState(false);
    const studyDetailsStore = useStore(StoreType.StudyDetails);
    const patientDetailsStore = useStore(StoreType.PatientDetails);
    const descriptionsStore = useStore(StoreType.Descriptions);
    const reportsStore = useStore(StoreType.PatientReports);
    const findingPropertiesStore = useStore(StoreType.FindingProperties);
    const findingsStore = useStore(StoreType.Findings);
    const rtuStore = useStore(StoreType.RealTimeUpdates);
    const descriptionPropertyTypeCodesStore = useStore(
      StoreType.DescriptionPropertyTypeCodes
    );

    const isStudyDetailsLoading = useStudyDetailsLoading();
    const descriptionId = Number(id);

    const isReaderAdminConnected =
      rtuStore.realTimeUpdatesConfig.isReaderAdminConnected;
    const reportDetails = reportsStore.patientReports.get(descriptionId);
    const descriptionDetails = descriptionsStore.descriptionById(descriptionId);
    const patientId = String(descriptionDetails?.patientId);
    const patientDetails = patientDetailsStore.patientById(patientId);
    const patientPropertyCodings = patientDetailsStore.propertyCodings(
      patientId
    );
    const studyStatus = descriptionDetails?.isCompleted
      ? StudyStatus.Completed
      : StudyStatus.InProgress;

    const descriptionStatusesDelayedLoading = useDelayedLoading(
      findingsStore.descriptionStatusesLoading,
      1000
    );

    const findingsCompleted =
      !descriptionStatusesDelayedLoading &&
      findingsStore.hasRequiredData(descriptionId);

    const hasRequiredData = {
      [Routes.StudyDetails]:
        !descriptionPropertyTypeCodesStore.propertyTypeCodesExtraInfoError &&
        descriptionsStore.hasRequiredData(descriptionId, Routes.StudyDetails),
      [Routes.StudyConclusion]:
        !descriptionPropertyTypeCodesStore.propertyTypeCodesExtraInfoError &&
        !findingsStore.eventCodingsError &&
        descriptionsStore.hasRequiredData(
          descriptionId,
          Routes.StudyConclusion
        ),
      [Routes.StudyFindings]:
        findingsCompleted &&
        !descriptionPropertyTypeCodesStore.propertyTypeCodesExtraInfoError &&
        !findingsStore.eventCodingsError
    };

    const getTabStatus = (route: Routes) =>
      hasRequiredData[route]
        ? StatusMarkState.Completed
        : StatusMarkState.Uncompleted;

    const isExportEnabled = Object.values(hasRequiredData).every(Boolean);

    const isStudyOpenedWarningVisible = descriptionsStore.isReadOnly(
      descriptionId
    );

    const saveStatus = useMemo(
      () =>
        combineSaveStatus([
          studyDetailsStore.saveStatus,
          findingPropertiesStore.saveStatus,
          patientDetailsStore.saveStatus,
          descriptionsStore.saveStatus,
          findingsStore.saveStatus
        ]),
      [
        studyDetailsStore.saveStatus,
        findingPropertiesStore.saveStatus,
        patientDetailsStore.saveStatus,
        descriptionsStore.saveStatus,
        findingsStore.saveStatus
      ]
    );

    useEffect(() => {
      findingsStore.activeDescriptionId = descriptionId;
      reportsStore.getReportsByIds([descriptionId]);
      reportsStore.activeReportId = descriptionId;
      findingsStore.loadReportHeadModel(descriptionId);
      findingsStore.loadDescriptionStatus(descriptionId);
      descriptionPropertyTypeCodesStore.loadPropertyTypeCodesExtraInfo(
        DescriptionPropertyTypes.DiagnosticSignificance
      );
      findingsStore.loadEventCodings(descriptionId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descriptionId]);

    useEffect(() => {
      if (reportDetails) {
        descriptionsStore.addDescriptionDetailsData(
          descriptionId,
          reportDetails.description!
        );
        findingsStore.loadScreenshots(
          reportDetails.studies.map((study) => study.studyId)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descriptionId, reportDetails, reportsStore.patientReports]);

    useEffect(() => {
      return () => {
        patientDetailsStore.clearPropertyCodingsError();
      };
    }, [patientDetailsStore]);

    useEffect(() => {
      if (descriptionDetails?.patientId) {
        patientDetailsStore.loadPatientDetails(
          descriptionDetails.patientId,
          descriptionId
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientDetails.patientId, descriptionDetails?.patientId]);

    useEffect(() => {
      studyDetailsStore.getUserSettingsConfig();
    }, [studyDetailsStore]);

    useEffect(() => {
      windowWidth > MD_SCREEN_MAX_WIDTH && isModalOpen && setOpenModal(false);
    }, [windowWidth, isModalOpen]);

    const onStopResize = useCallback(
      (e: HandlerProps) => {
        const { flex } = e.component.props;
        studyDetailsStore.updateUserSettingsConfig({
          reportSize: flex
        });
      },
      [studyDetailsStore]
    );

    const toggleModal = useCallback(() => {
      setOpenModal(!isModalOpen);
    }, [isModalOpen, setOpenModal]);

    useHotkeys(
      createShortcutCombination({
        baseKey: shortcutsBaseKeys.CMD,
        key: '\\'
      }),
      (event) => {
        event.preventDefault();

        handleSwitchClick();
      },
      {},
      [windowWidth, toggleModal, studyDetailsStore]
    );

    const handleSwitchClick = useCallback(async () => {
      if (windowWidth > MD_SCREEN_MAX_WIDTH) {
        await studyDetailsStore.updateUserSettingsConfig({
          reportCollapsed: !studyDetailsStore.userSettingsConfig
            ?.reportCollapsed
        });
      } else {
        toggleModal();
      }
    }, [studyDetailsStore, windowWidth, toggleModal]);

    const resizeConfig = useMemo(
      () => ({
        size: studyDetailsStore.userSettingsConfig.reportSize,
        collapsed: studyDetailsStore.userSettingsConfig.reportCollapsed
      }),
      [
        studyDetailsStore.userSettingsConfig.reportCollapsed,
        studyDetailsStore.userSettingsConfig.reportSize
      ]
    );

    const isReportExpanded = useMemo(() => {
      if (windowWidth > MD_SCREEN_MAX_WIDTH) {
        return !resizeConfig.collapsed;
      }
      return isModalOpen;
    }, [windowWidth, resizeConfig, isModalOpen]);

    return (
      <LoadingOverlay loading={isStudyDetailsLoading}>
        <PageLeavePrompt
          when={saveStatus !== StatusIndicatorStatus.Saved}
          message={t(
            'You may loose some of the data by leaving this page, proceed anyway?'
          )}
        />
        {descriptionDetails && reportDetails && (
          <div
            data-testid='study-details'
            className={styles['study-details-page']}
          >
            <FindingsConfirmationProvider
              skipWarnings={
                studyDetailsStore.userSettingsConfig.skipReportWarnings
              }
            >
              <StudyDetailsHeader
                isExportDisabled={!isExportEnabled}
                saveStatus={saveStatus}
                reportDetails={reportDetails}
                studyStatus={studyStatus}
                patientDetails={patientDetails}
                descriptionDetails={descriptionDetails}
                hasGiveUpSaving={
                  studyDetailsStore.hasGiveUpSaving ||
                  patientDetailsStore.hasGiveUpSaving ||
                  descriptionsStore.hasGiveUpSaving
                }
                onRetryAllRequests={() => {
                  studyDetailsStore.retryAllRequests();
                  patientDetailsStore.retryAllRequests();
                  descriptionsStore.retryAllRequests();
                }}
                patientPropertyCodings={patientPropertyCodings}
                isStudyUnsynced={!isReaderAdminConnected}
                isStudyOpenedWarningVisible={isStudyOpenedWarningVisible}
                toggleReportPreviewModal={handleSwitchClick}
              />
            </FindingsConfirmationProvider>
            <div
              className={cn(
                styles.content,
                (!isReaderAdminConnected || isStudyOpenedWarningVisible) &&
                  styles['has-warning-msg']
              )}
            >
              <div className={styles.header}>
                <div className={styles.tabs}>
                  {studyNavTabs.map(({ label, route }) => (
                    <NavTab
                      exact
                      key={label}
                      label={t(label)}
                      component={NavLink}
                      className={styles.tab}
                      theme={NavTabTheme.Merged}
                      status={getTabStatus(route)}
                      activeClassName={NavTab.activeStyle}
                      to={generatePath(route, { id })}
                      curveLeftClassName={styles['curve-left']}
                    />
                  ))}
                </div>
                <div className={styles['switch-wrap']}>
                  <span className={styles['switch-text']}>
                    {isReportExpanded
                      ? t('Report preview')
                      : t('Show report preview')}
                  </span>
                  <Switch
                    checked={isReportExpanded}
                    onChange={handleSwitchClick}
                  />
                </div>
              </div>
              <ResizeContainer
                onStopResize={onStopResize}
                config={resizeConfig}
              />
              <ReportPreviewModal
                visible={isModalOpen}
                onVisibilityChange={toggleModal}
                descriptionStatus={studyStatus}
              />
            </div>
          </div>
        )}
      </LoadingOverlay>
    );
  })
);
